import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () =>
    import ('@/views/Home');
const Dashboard = () =>
    import ('@/views/Dashboard');
const UserChangePwd = () =>
    import ('@/views/system/user/changepwd');
const UserProfile = () =>
    import ('@/views/system/user/profile');
const UserList = () =>
    import ('@/views/system/user/list');
const RoleList = () =>
    import ('@/views/system/role/list');
const MenuList = () =>
    import ('@/views/system/menu/list');
const DictionaryList = () =>
    import ('@/views/system/dictionary/list');
const OperationlogList = () =>
    import ('@/views/system/operationlog/list');
//登录日志
const LoginLogList = () =>
    import ('@/views/system/loginLog/list');

//用户管理
const WeChatUserList = () =>
    import ('@/views/weChatUser/list');

const Login = resolve => require(['@/views/Login'], resolve);

//首页幻灯

const bannerList = () =>
    import ('@/views/contentManagement/banner/list');
const bannerView = () =>
    import ('@/views/contentManagement/banner/view');
const bannerAdd = () =>
    import ('@/views/contentManagement/banner/add');
const bannerEdit = () =>
    import ('@/views/contentManagement/banner/edit');
//住宿
const accommodationList = () =>
    import ('@/views/contentManagement/accommodation/list');
const accommodationView = () =>
    import ('@/views/contentManagement/accommodation/view');
const accommodationAdd = () =>
    import ('@/views/contentManagement/accommodation/add');
const accommodationEdit = () =>
    import ('@/views/contentManagement/accommodation/edit');

//周邊一日游
const tourList = () =>
    import ('@/views/contentManagement/tour/list');
const tourView = () =>
    import ('@/views/contentManagement/tour/view');
const tourAdd = () =>
    import ('@/views/contentManagement/tour/add');
const tourEdit = () =>
    import ('@/views/contentManagement/tour/edit');

//旅行社
const tourGroupList = () =>
    import ('@/views/contentManagement/tourGroup/list');
const tourGroupView = () =>
    import ('@/views/contentManagement/tourGroup/view');
const tourGroupAdd = () =>
    import ('@/views/contentManagement/tourGroup/add');
const tourGroupEdit = () =>
    import ('@/views/contentManagement/tourGroup/edit');

//其他页面
const otherPageList = () =>
    import ('@/views/contentManagement/otherPage/list');
const otherPageView = () =>
    import ('@/views/contentManagement/otherPage/view');
const otherPageEdit = () =>
    import ('@/views/contentManagement/otherPage/edit');

//美食页面
const deliciousFoodsList = () =>
    import ('@/views/contentManagement/foods/list');
const deliciousFoodsView = () =>
    import ('@/views/contentManagement/foods/view');
const deliciousFoodsAdd = () =>
    import ('@/views/contentManagement/foods/add');
const deliciousFoodsEdit = () =>
    import ('@/views/contentManagement/foods/edit');

//美食种类页面
const FoodsCategoryList = () =>
    import ('@/views/contentManagement/foodsCategory/list');
const FoodsCategoryView = () =>
    import ('@/views/contentManagement/foodsCategory/view');
const FoodsCategoryAdd = () =>
    import ('@/views/contentManagement/foodsCategory/add');
const FoodsCategoryEdit = () =>
    import ('@/views/contentManagement/foodsCategory/edit');

//商户页面
const merchantList = () =>
    import ('@/views/contentManagement/merchant/list');
const merchantView = () =>
    import ('@/views/contentManagement/merchant/view');
const merchantAdd = () =>
    import ('@/views/contentManagement/merchant/add');
const merchantEdit = () =>
    import ('@/views/contentManagement/merchant/edit');



//简介
const introductionList = () =>
    import ('@/views/contentManagement/strategy/introduction/list');
const introductionView = () =>
    import ('@/views/contentManagement/strategy/introduction/view');
const introductionAdd = () =>
    import ('@/views/contentManagement/strategy/introduction/add');
const introductionEdit = () =>
    import ('@/views/contentManagement/strategy/introduction/edit');


//攻略
const strategyList = () =>
    import ('@/views/contentManagement/strategy/strategy/list');
const strategyView = () =>
    import ('@/views/contentManagement/strategy/strategy/view');
const strategyAdd = () =>
    import ('@/views/contentManagement/strategy/strategy/add');
const strategyEdit = () =>
    import ('@/views/contentManagement/strategy/strategy/edit');

//票务
const ticketList = () =>
    import ('@/views/contentManagement/ticket/list');
const ticketView = () =>
    import ('@/views/contentManagement/ticket/view');
const ticketAdd = () =>
    import ('@/views/contentManagement/ticket/add');
const ticketEdit = () =>
    import ('@/views/contentManagement/ticket/edit');


//景色
const sceneryList = () =>
    import ('@/views/contentManagement/scenery/list');
const sceneryView = () =>
    import ('@/views/contentManagement/scenery/view');
const sceneryAdd = () =>
    import ('@/views/contentManagement/scenery/add');
const sceneryEdit = () =>
    import ('@/views/contentManagement/scenery/edit');

//商务合作
const cooperationList = () =>
    import ('@/views/cooperation/list');


Vue.use(VueRouter);

const routes = [

    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/',
        name: '系统首页',
        component: Home,
        redirect: '/dashboard',
        iconCls: 'iconfont icon-home', // 图标样式class
        children: [
            { path: 'dashboard', component: Dashboard, name: '系统主页' },
        ]
    },

    {
        path: '/',
        component: Home,
        name: '用户管理',
        children: [
            { path: 'weChatUser/list', component: WeChatUserList, name: '用户管理列表' },
        ]
    },

    {
        path: '/',
        component: Home,
        name: '内容管理',
        children: [
            { path: '/banner/list', component: bannerList, name: '首页换灯' },
            { path: '/banner/view', component: bannerView, name: '首页换灯查看' },
            { path: '/banner/add', component: bannerAdd, name: '首页换灯添加' },
            { path: '/banner/edit', component: bannerEdit, name: '首页换灯编辑' },
            { path: '/otherPage/list', component: otherPageList, name: '其他页面' },
            { path: '/otherPage/view', component: otherPageView, name: '其他页面查看' },
            { path: '/otherPage/edit', component: otherPageEdit, name: '其他页面编辑' },
            { path: '/strategy/introduction/list', component: introductionList, name: '简介' },
            { path: '/strategy/introduction/view', component: introductionView, name: '简介查看' },
            { path: '/strategy/introduction/add', component: introductionAdd, name: '简介添加' },
            { path: '/strategy/introduction/edit', component: introductionEdit, name: '简介编辑' },
            { path: '/strategy/strategy/list', component: strategyList, name: '攻略' },
            { path: '/strategy/strategy/view', component: strategyView, name: '攻略查看' },
            { path: '/strategy/strategy/add', component: strategyAdd, name: '攻略添加' },
            { path: '/strategy/strategy/edit', component: strategyEdit, name: '攻略编辑' },
            { path: '/ticket/list', component: ticketList, name: '票务' },
            { path: '/ticket/view', component: ticketView, name: '票务查看' },
            { path: '/ticket/add', component: ticketAdd, name: '票务添加' },
            { path: '/ticket/edit', component: ticketEdit, name: '票务编辑' },
            {path: '/tour/list', component: tourList, name: '周边一日游'},
            {path: '/tour/view', component: tourView, name: '周边一日游查看'},
            {path: '/tour/add', component: tourAdd, name: '周边一日游添加'},
            {path: '/tour/edit', component: tourEdit, name: '周边一日游编辑'},
            {path: '/tourGroup/list', component: tourGroupList, name: '旅行社'},
            {path: '/tourGroup/view', component: tourGroupView, name: '旅行社查看'},
            {path: '/tourGroup/add', component: tourGroupAdd, name: '旅行社添加'},
            {path: '/tourGroup/edit', component: tourGroupEdit, name: '旅行社编辑'},
        ]
    },

    {
        path: '/',
        component: Home,
        name: '景色',
        children: [
            { path: '/scenery/list', component: sceneryList, name: '景色' },
            { path: '/scenery/view', component: sceneryView, name: '景色查看' },
            { path: '/scenery/add', component: sceneryAdd, name: '景色添加' },
            { path: '/scenery/edit', component: sceneryEdit, name: '景色编辑' },
        ]
    },

    {
        path: '/',
        component: Home,
        name: '住宿',
        children: [
            {path: '/accommodation/list', component:accommodationList, name: '住宿'},
            {path: '/accommodation/view', component:accommodationView, name: '住宿查看'},
            {path: '/accommodation/add', component: accommodationAdd, name: '住宿添加'},
            {path: '/accommodation/edit', component:accommodationEdit, name: '住宿编辑'},
        ]
    },

    {
        path: '/',
        component: Home,
        name: '美食',
        children: [
            { path: '/foods/list', component: deliciousFoodsList, name: '美食' },
            { path: '/foods/view', component: deliciousFoodsView, name: '美食查看' },
            { path: '/foods/add', component: deliciousFoodsAdd, name: '添加美食' },
            { path: '/foods/edit', component: deliciousFoodsEdit, name: '编辑美食' },
        ]
    },
    ,
    {
        path: '/',
        component: Home,
        name: '美食种类',
        children: [
            { path: '/foodsCategory/list', component: FoodsCategoryList, name: '美食种类' },
            { path: '/foodsCategory/view', component: FoodsCategoryView, name: '美食种类查看' },
            { path: '/foodsCategory/add', component: FoodsCategoryAdd, name: '添加美食种类' },
            { path: '/foodsCategory/edit', component: FoodsCategoryEdit, name: '编辑美食种类' },
        ]
    },
    {
        path: '/',
        component: Home,
        name: '商户',
        children: [
            { path: '/merchant/list', component: merchantList, name: '商户' },
            { path: '/merchant/view', component: merchantView, name: '商户查看' },
            { path: '/merchant/add', component: merchantAdd, name: '添加商户' },
            { path: '/merchant/edit', component: merchantEdit, name: '编辑商户' },
        ]
    },
    {
        path: '/',
        component: Home,
        name: '商务合作',
        children: [
            { path: '/businessCooperation/list', component: cooperationList, name: '商务合作' },
        ]
    },

    {
        path: '/system',
        component: Home,
        name: '系统管理',
        children: [
            { path: 'user/profile', component: UserProfile, name: '个人信息' },
            { path: 'user/changepwd', component: UserChangePwd, name: '修改密码', },
            { path: 'user/list', component: UserList, name: '系统用户' },
            { path: 'role/list', component: RoleList, name: '角色管理' },
            { path: 'menu/list', component: MenuList, name: '菜单管理' },
            { path: 'dictionary/list', component: DictionaryList, name: '数据字典' },
            { path: 'operationlog/list', component: OperationlogList, name: '操作日志' },
            { path: 'loginLog/list', component: LoginLogList, name: '登录日志' },
        ]
    }

];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
router.beforeEach((to, from, next) => {
    if (to.path.startsWith('/login')) {
        window.sessionStorage.removeItem('Authorization');
        next()
    } else {
        let Authorization = window.sessionStorage.getItem('Authorization');
        if (!Authorization) {
            next({ path: '/login' })
        } else {
            next()
        }
    }
});

export default router
